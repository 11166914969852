import React, { useMemo, useState } from "react";
import styles from "./formStyles.module.css";
import { useFormik } from 'formik';
import {validationSchema} from "../validations/validationSchema"
import { AiOutlineInfoCircle } from "react-icons/ai";

//components
import Input from "../../../reactComponents/input/Input";
import Select from "../../../reactComponents/select/Select";
import Checkbox from "../../../reactComponents/checkbox/CheckBox";
import Modal from "../../../reactComponents/modal/Modal";

//adapter
import {findValueMatch} from "../utils/utils"

const ConfigLuz = ({
  organization,
  socialBondData,
  topeGasOptions,
  topegasPVPC,
  nonTopeGas,
  selfConsumption,
  showAccumulatedCompensation,
  annualizationType
}) => {

  const formik = useFormik({
    initialValues: {
      organization: {
        name: organization.name,
        code: organization.code,
        phone: organization.phone,
        social_bond_financing_value: organization.social_bond_financing_value,
        recalculate_import_ie: organization.recalculate_import_ie,
        variance_total_gas: organization.variance_total_gas,
        variance_total_luz:  organization.variance_total_luz,
        variance_total_luz_pyme: organization.variance_total_luz_pyme
      },
    },
    validationSchema: validationSchema,
  });


  //modal

  const [showLuz20Modal, setShowLuz20Modal] = useState(false);

  const luz20ModalText = [
    {
      header: "¿Permitir comparativas con Autoconsumo?",
      text: "Permite la generación de comparativas contra clientes que \
      dispongan de autoconsumo en sus facturas.",
    },
    {
      header: "¿Permitir comparativas contra PVPC con Bono Social?",
      text: "Permite la generación de comparativas contra clientes que \
      estén acogidos al plan de PVPC con acceso al Bono Social.",
    },
    {
      header: "¿Habilitar 'Ajuste Consumo' en comparativa?",
      text: "Permite cambiar la distribución de los consumos en los distintos \
      periodos de 2.0TD (Punta / Llano / Valle) y realizar un recálculo de las \
      comparativas con esta nueva distribución.",
    },
    {
      header: "¿Habilitar 'Ajuste Potencia Contratada' en comparativa?",
      text: "Permite modificar las potencias contratadas del usuario para las \
      comparativas de 2.0TD (P1 o P2) y posteriormente realizar un recálculo de las \
      comparativas con los nuevos valores de potencia contratada.",
    },
    {
      header: "¿Habilitar 'Ajuste Renovación Precio' en comparativa?",
      text: "Permite modificar los precios de Energía o Potencia del cliente para volver \
      a generar una comparativa con los nuevos valores.",
    },
    {
      header: "¿Habilitar la extracción de datos del QR?",
      text: "Permite habilitar la lectura del QR de la factura y la extracción de datos",
    }
  ];


  const [allowSelfConsumption, setAllowSelfConsumption] = useState(
    organization.allow_self_consumption_calculations
  );
  const [allowBonoSocialCalc, setAllowBonoSocialCalc] = useState(
    organization.allow_pvpc_bono_social_calculations
  );
  const [allowEnergyAdjustment, setAllowEnergyAdjustment] = useState(
    organization.allow_energy_adjustment
  );
  const [allowPowerAdjustment, setAllowPowerAdjustment] = useState(
    organization.allow_power_adjustment
  );
  const [allowRenewalPrice, setAllowRenewalPrice] = useState(
    organization.allow_renewal_price
  );

  const [testingScript, setTestingScript] = useState(
    organization.qr_scanner
  );

  const handleChange= () => {
   setTestingScript(!testingScript)
   !testingScript && setInvoiceconsistency(false)
  }

  const [invoiceConsistency, setInvoiceconsistency] = useState(organization.validate_invoice_consistency);

  const handleCheckboxChange = (setter, state) => () => {
    setter(!state);
  };

  const configLuz2CheckboxData = [
    {
      onChange: handleCheckboxChange(
        setAllowSelfConsumption,
        allowSelfConsumption
      ),
      checked: allowSelfConsumption,
      name: "organization[allow_self_consumption_calculations]",
      id: "organization_allow_self_consumption_calculations",
      label: "¿Permitir comparativas con Autoconsumo?",
    },
    {
      onChange: handleCheckboxChange(
        setAllowBonoSocialCalc,
        allowBonoSocialCalc
      ),
      checked: allowBonoSocialCalc,
      name: "organization[allow_pvpc_bono_social_calculations]",
      id: "organization_allow_pvpc_bono_social_calculations",
      label: "¿Permitir comparativas contra PVPC con Bono Social?",
    },
    {
      onChange: handleCheckboxChange(setAllowEnergyAdjustment, allowEnergyAdjustment),
      checked: allowEnergyAdjustment,
      name: "organization[allow_energy_adjustment]",
      id: "organization_allow_energy_adjustment",
      label: `¿Habilitar "Ajuste Consumo" en comparativa?`,
    },
    {
      onChange: handleCheckboxChange(setAllowPowerAdjustment, allowPowerAdjustment),
      checked: allowPowerAdjustment,
      name: "organization[allow_power_adjustment]",
      id: "organization_allow_power_adjustment",
      label: `¿Habilitar "Ajuste Potencia Contratada" en comparativa?`,
    },
    {
      onChange: handleCheckboxChange(setAllowRenewalPrice, allowRenewalPrice),
      checked: allowRenewalPrice,
      name: "organization[allow_renewal_price]",
      id: "organization_allow_renewal_price",
      label: `¿Habilitar "Ajuste Renovación Precio" en comparativa?`,
    },
    {
      onChange: handleCheckboxChange(handleChange, testingScript),
      checked: testingScript,
      name: "organization[qr_scanner]",
      id: "organization_qr_scanner",
      label: `¿Habilitar la extracción de datos del QR?`,
    },
  ];

  const annualizationTypeData = annualizationType.map((item) => ({
    id: item[1],
    name: item[0],
  }));

  const socialBond = socialBondData.map((item) => ({
    id: item[1],
    name: item[0],
  }));

  const topeGasData = topeGasOptions.map((item, i) => ({
    id: item[1],
    name: item[0],
  }));

  const topeGasPVPCData = topegasPVPC.map((item) => ({
    id: item[1],
    name: item[0],
  }));

  const nonTopeGasData = nonTopeGas.map((item) => ({
    id: item[1],
    name: item[0],
  }));
  
  const selfConsumptionData = selfConsumption.map((item) => ({
    id: item[1],
    name: item[0],
  }));

  const showAccumulatedCompensationData = showAccumulatedCompensation.map((item) => ({
    id: item[1],
    name: item[0],
  }));

  const annualizationTypePlaceholder = findValueMatch(
    annualizationTypeData,
    organization.annualization_type
  );

  const bonoSocialPlaceholder = findValueMatch(
    socialBond,
    organization.social_bond_financing
  );

  const topeGasDataPlaceholder = findValueMatch(
    topeGasData,
    organization.tope_gas
  );

  const topeGasPVPCPlaceholder = findValueMatch(
    topeGasPVPCData,
    organization.tope_gas_pvpc
  );

  const nonTopGasPlaceholder = findValueMatch(
    nonTopeGasData,
    organization.non_tope_gas
  );

  const selfConsumPlaceholder = findValueMatch(
    selfConsumptionData,
    organization.self_consumption_logic
  );

  const showAccumulatedCompensationPlaceholder = findValueMatch(
    showAccumulatedCompensationData,
    organization.show_accumulated_compensation
  );

  let [selectedValue, setSelectedValue] = useState(
    organization.social_bond_financing.toLowerCase()
  );
  const onSelectValueFunction = (selectedItem, item) => {
    setSelectedValue(item.toLowerCase());
  };
  const selectStateUpdate =  useMemo(()=> (<Select
    multiSelect={false}
    searchBar={true}
    name="Financiación bono social"
    data={socialBond}
    placeholder={bonoSocialPlaceholder}
    dataSelected={organization.social_bond_financing}
    selectId="organization_social_bond_financing"
    selectName="organization[social_bond_financing]"
    className={styles.titleSelect}
    reactSetter={onSelectValueFunction}
  />), []    
  )
  return (
    <div>
      {showLuz20Modal && (
        <Modal data={luz20ModalText} onClose={() => setShowLuz20Modal(false)} />
      )}
      <h3 className={styles.title}>
        Configuración Luz 2.0TD
        <AiOutlineInfoCircle
          className={styles.infoIcon}
          onClick={() => setShowLuz20Modal(true)}
        />
      </h3>
      <div className={styles.scrollDiv}>
      <div className={styles.checkBoxContent}>
        {configLuz2CheckboxData.map((item) => {
          return (
            <Checkbox
              onChange={item.onChange}
              checked={item.checked}
              name={item.name}
              id={item.id}
              key={item.id}
              label={item.label}
            />
          );
        })}
        {testingScript ?  
             <Checkbox
              onChange={handleCheckboxChange(setInvoiceconsistency,invoiceConsistency)}
              checked={invoiceConsistency}
              name="organization[validate_invoice_consistency]"
              id="organization_validate_invoice_consistency"
              key="organization_validate_invoice_consistency"
              label="Detectar facturas incoherentes"
            /> : ""}
      </div>
      </div>

      <div className={styles.confLuz}>
        <div>
          <h5 className={styles.headerColor}>Tipo de Anualización</h5>
          <p className={styles.smallText}>
            Indica la lógica a seguir para calcular la anualización
          </p>
        </div>

        <Select
          multiSelect={false}
          searchBar={true}
          name=""
          data={annualizationTypeData}
          placeholder={annualizationTypePlaceholder}
          dataSelected={organization.annualization_type}
          selectId="organization_annualization_type"
          selectName="organization[annualization_type]"
        />
      </div>

      <div className={styles.selectsContainer}>
        {selectStateUpdate}
      </div>
      <div className={styles.formBlockContainer}>
        <div className={styles.financingBonoSocialContainer}>
          <div>
            {selectedValue == "replicate_or_calculate" || selectedValue == "calculate" ? (
              <>
                <Input
                  label="Valor de financiación bono social"
                  type="text"
                  name="organization[social_bond_financing_value]"
                  id="organization_social_bond_financing_value"
                  placeholder={organization.social_bond_financing_value}
                  value={formik.values.organization.social_bond_financing_value}
                  onChange={formik.handleChange}
                  error={formik.errors.organization?.social_bond_financing_value}
                  touched={formik.touched.organization?.social_bond_financing_value}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <span className={styles.space}></span>
          <div>
            <Input
              label="IE para recálculo de importes (%)"
              type="text"
              name="organization[recalculate_import_ie]"
              id="organization_recalculate_import_ie"
              placeholder={organization.recalculate_import_ie}
              value={formik.values.organization.recalculate_import_ie}
              onChange={formik.handleChange}
              error={formik.errors.organization?.recalculate_import_ie}
              touched={formik.touched.organization?.recalculate_import_ie}
            />
          </div>
        </div>
      </div>
      <div className={styles.configuracionLuzContainer}>
        <div className={styles.confLuz}>
          <h5 className={styles.headerColor}>
            Factura (Mercado Libre) con Coste tope gas
          </h5>
          <p className={styles.smallText}>
            Indica la lógica a seguir cuando la factura de orígen es de Mercado
            Libre y tiene coste tope de gas desglosado como concepto, y cuyo
            importe haya sido leído por eSave
          </p>
          <Select
            multiSelect={false}
            searchBar={true}
            data={topeGasData}
            placeholder={topeGasDataPlaceholder}
            dataSelected={organization.tope_gas}
            selectId="organization_tope_gas"
            selectName="organization[tope_gas]"
          />
        </div>
        <span className={styles.space}></span>
        <div className={styles.confLuz}>
          <h5 className={styles.headerColor}>Factura (PVPC) Coste tope gas</h5>
          <p className={styles.smallText}>
            Indica la lógica a seguir cuando la factura de orígen es PVPC y con
            fecha fin de facturación posterior al 15 de Junio de 2022 (es decir
            lleva impactado el coste tope de gas en los precios de energía)
          </p>
          <Select
            classNameName={styles.headerColor}
            multiSelect={false}
            searchBar={true}
            name=""
            data={topeGasPVPCData}
            placeholder={topeGasPVPCPlaceholder}
            dataSelected={organization.tope_gas_pvpc}
            selectId="organization_tope_gas_pvpc"
            selectName="organization[tope_gas_pvpc]"
          />
        </div>
        <span className={styles.space}></span>
        <div className={styles.confLuz}>
          <h5 className={styles.headerColor}>
            Factura sin coste tope gas identificado
          </h5>
          <p className={styles.smallText}>
            Indica la lógica a seguir cuando la factura de orígen no es PVPC y
            no se ha detectado el concepto "coste tope de gas". Afecta tanto a
            PVPC (previas al 15 de Junio 2022) como a mercado libre
          </p>
          <Select
            multiSelect={false}
            searchBar={true}
            title=""
            data={nonTopeGasData}
            placeholder={nonTopGasPlaceholder}
            dataSelected={organization.non_tope_gas}
            selectId="organization_non_tope_gas"
            selectName="organization[non_tope_gas]"
            classNameName={styles.headerColor}
          />
        </div>
      </div>
      <div className={styles.configuracionLuzContainer}>
        <div className={styles.confLuz}>
          <div>
            <h5 className={styles.headerColor}>
              Gestión Autoconsumo
            </h5>
            <p className={styles.smallText}>
              Indica la lógica a seguir para gestionar el autoconsumo
            </p>
          </div>

          <Select
            multiSelect={false}
            searchBar={true}
            name=""
            data={selfConsumptionData}
            placeholder={selfConsumPlaceholder}
            dataSelected={organization.self_consumption_logic}
            selectId="organization_self_consumption_logic"
            selectName="organization[self_consumption_logic]"
          />
        </div>

        <span className={styles.space}></span>
        <div className={styles.confLuz}>
          <div>
            <h5 className={styles.headerColor}>
              Gestión Acumulación Autoconsumo
            </h5>
            <p className={styles.smallText}>
              Indica la lógica a seguir para gestionar y mostrar la 
              acumulación de autoconsumo
            </p>
          </div>

          <Select
            multiSelect={false}
            searchBar={true}
            name=""
            data={showAccumulatedCompensationData}
            placeholder={showAccumulatedCompensationPlaceholder}
            dataSelected={organization.show_accumulated_compensation}
            selectId="organization_show_accumulated_compensation"
            selectName="organization[show_accumulated_compensation]"
          />
        </div>
      </div>

      <div className={styles.varianzasContainer}>
        <div>
          <h5 className={styles.headerColorBlock}>Configuración Luz <span style={{ color: 'red' }}>*</span></h5>
          <h6 className={styles.subheaderColorBlock}>Varianza error luz (entre 0 y 100%)</h6>
          <p className={styles.smallText}>
            Esta varianza indica el desvío que se permite en la APP para validar
            el importe total de luz y dar el procesamiento como válido
          </p>
          <Input
            type="number"
            name="organization[variance_total_luz]"
            id="organization_variance_total_luz"
            placeholder={organization.variance_total_luz}
            value={formik.values.organization.variance_total_luz}
            onChange={formik.handleChange}
            error={formik.errors.organization?.variance_total_luz} 
            touched={formik.touched.organization?.variance_total_luz } 
            />
        </div>
        <span className={styles.space}></span>
        <div>
          <h5 className={styles.headerColorBlock}>Configuración Gas <span style={{ color: 'red' }}>*</span></h5>
          <h6 className={styles.subheaderColorBlock}>Varianza error gas (entre 0 y 100%)</h6>

          <p className={styles.smallText}>
            Esta varianza indica el desvío que se permite en la APP para validar
            el importe total de gas y dar el procesamiento como válido
          </p>

          <Input
            type="number"
            name="organization[variance_total_gas]"
            id="organization_variance_total_gas"
            placeholder={organization.variance_total_gas}
            value={formik.values.organization.variance_total_gas}
            onChange={formik.handleChange}
            error={formik.errors.organization?.variance_total_gas}  
            touched={formik.touched.organization?.variance_total_gas}
          />
        </div>
        <span className={styles.space}></span>
        <div>
          <h5 className={styles.headerColorBlock}>Configuración Luz 3.0TD <span style={{ color: 'red' }}>*</span></h5>
          <h6 className={styles.subheaderColorBlock}>Varianza error luz (entre 0 y 100%)</h6>
          <p className={styles.smallText}>
            Esta varianza indica el desvío que se permite en la APP para validar
            el importe total de luz (negocios) y dar el procesamiento como
            válido
          </p>
          <Input
            type="number"
            name="organization[variance_total_luz_pyme]"
            id="organization_variance_total_luz_pyme"
            placeholder={organization.variance_total_luz_pyme}
            value={formik.values.organization.variance_total_luz_pyme}
            onChange={formik.handleChange}
            error={formik.errors.organization?.variance_total_luz_pyme}  
            touched={formik.touched.organization?.variance_total_luz_pyme}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigLuz;
